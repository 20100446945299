<template>
    <div
        class="custom-input"
        :class="{ 'custom-input--empty': !errorMessage && !value }"
    >
        <label v-if="label" class="custom-input__label" :for="id">{{
            label
        }}</label>
        <input
            :id="id"
            :name="name"
            class="custom-input__input"
            :type="type"
            :placeholder="placeholder"
            :value="value"
            @input="$emit('input', $event.target.value)"
        />
        <small class="text-danger">{{ errorMessage }}</small>
    </div>
</template>
<script>
export default {
    props: {
        value: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: 'text'
        },
        placeholder: {
            type: String,
            default: ''
        },
        errorMessage: {
            type: String,
            default: ''
        }
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {}
};
</script>

<style lang="scss">
.custom-input {
    &--empty {
        opacity: 0.3;
    }
}

.custom-input__label {
    font-size: 14px;
    color: $color-blue;
    font-weight: 600;
    margin-bottom: 0;
}

.custom-input__input {
    display: inline-block;
    width: 100%;
    padding: 0 0 5px 0;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid $color-black;
    font-family: $font-family-input;
    font-size: 20px;
    font-weight: 600;
    color: $color-black;

    &::placeholder {
        font-weight: normal;
    }
}
</style>
