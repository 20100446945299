// define a mixin object
const csrfMixin = {
    props: {
        csrfToken: {
            type: String,
            required: true
        }
    }
};

export default csrfMixin;
