<template>
    <div class="navbar-item__subitem">
        <div class="navbar-mobile-toggle" @click.stop="toggleIsOpen">
            {{ menuItem.pageTilte }}
        </div>

        <div
            v-click-outside="closeSubMenu"
            class="submenu"
            :class="{ 'submenu--is-open': submenuIsOpen }"
        >
            <ul class="submenu__links">
                <li
                    v-for="(subItem, index) in menuItem.menuItems"
                    :key="index"
                    class="submenu__item"
                >
                    <a :href="subItem.url" class="submenu__link">
                        {{ subItem.name }}</a
                    >
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import ClickOutside from 'vue-click-outside';

export default {
    directives: {
        ClickOutside
    },
    props: {
        menuItem: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    data() {
        return {
            submenuIsOpen: false
        };
    },

    methods: {
        toggleIsOpen() {
            this.submenuIsOpen = !this.submenuIsOpen;
        },
        closeSubMenu() {
            this.submenuIsOpen = false;
        }
    }
};
</script>

<style lang="scss">
.navbar-item__subitem {
    width: 100%;
    min-height: 50px;
    position: absolute;
}

.submenu {
    background-color: $color-dark-orange;
    position: absolute;

    overflow: hidden;
    visibility: hidden;

    &--is-open {
        visibility: visible;
        height: auto;
        z-index: 1;
        top: 55px;
        width: 100%;

        @include media-breakpoint-up(lg) {
            visibility: hidden;
        }
    }

    @include media-breakpoint-up(lg) {
        background-color: $color-orange;
        position: absolute;
        top: 100px;
        right: 0;

        .navbar-item:hover & {
            visibility: visible;
        }
    }
}

.submenu__links {
    list-style-type: none;
    padding: 0;
}

.submenu__item {
    display: flex;
}

.submenu__link {
    font-family: $font-family-heading;
    font-weight: $font-weight-medium;
    color: $color-white;
    font-size: 20px;
    padding: 15px 10px;
    width: 100%;
    text-align: center;

    &:hover {
        color: $color-white;
        background-color: $color-dark-orange;
        text-decoration: none;
    }

    @include media-breakpoint-up(lg) {
        text-decoration: none;
        color: $color-white;
        min-width: 180px;
    }
}

.navbar-mobile-toggle {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;

    @include media-breakpoint-up(lg) {
        display: none;
    }
}
</style>
