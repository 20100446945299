<template>
    <div class="navbar">
        <a class="navbar-logo" href="/"> </a>
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-11 offset-md-1">
                    <div class="navbar-menu" :class="{ 'is-open': menuIsOpen }">
                        <ul class="navbar-menu__items">
                            <li
                                v-for="(menuItem, index) in menuItems"
                                :key="index"
                                class="navbar-item position-relative"
                            >
                                <nav-bar-submenu
                                    v-if="menuItem.menuItems.length"
                                    :menu-item="menuItem"
                                >
                                </nav-bar-submenu>
                                <a
                                    class="navbar-item__link"
                                    :href="menuItem.url"
                                    :class="{
                                        'is-active': menuItem.isActive,
                                        'has-items': menuItem.menuItems.length
                                    }"
                                    >{{ menuItem.name }}</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="navbar-hamburger">
                    <button
                        class="hamburger hamburger--spring"
                        :class="{ 'is-active': menuIsOpen }"
                        type="button"
                        @click.prevent="toggleHamburger"
                    >
                        <span class="hamburger-box">
                            <span class="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NavBarSubmenu from '@/components/navbar/navbar-submenu';
export default {
    components: {
        NavBarSubmenu
    },
    props: {
        menuItems: {
            type: Array,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            menuIsOpen: false
        };
    },

    methods: {
        toggleHamburger() {
            this.menuIsOpen = !this.menuIsOpen;
        }
    }
};
</script>
<style lang="scss">
@import '~@/vendor/scss/hamburgers';

.navbar {
    height: 60px;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 4;
    box-shadow: 0px 3px 13px -12px rgba(0, 0, 0, 0.63);
    background-color: $color-white;

    @include media-breakpoint-up(lg) {
        height: 100px;
    }
}

.navbar-logo {
    background-image: url('/content/img/navbar-logo.svg');
    position: absolute;
    left: 25px;
    top: 10px;
    height: 100%;
    width: 70px;
    background-repeat: no-repeat;
    z-index: 6;

    @include media-breakpoint-up(lg) {
        top: 20px;
        height: 100%;
        width: 115px;
    }
}

.navbar-menu {
    display: none;
    align-items: center;
    height: auto;
    width: 100%;

    @include media-breakpoint-up(lg) {
        position: relative;
        display: flex;
    }
}

.navbar-menu__items {
    margin: 0;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    list-style-type: none;
    padding: 0;
}

.navbar-item {
    display: flex;
    align-items: center;
    display: flex;
    width: 100%;
    margin-bottom: 5px;
    flex-direction: column;

    &:last-of-type {
        border: none;
        a {
            margin-right: 0;
        }
    }

    @include media-breakpoint-up(lg) {
        border: none;
        width: auto;
        height: 100%;
    }
}

.navbar-item__link {
    font-family: $font-family-heading;
    font-weight: $font-weight-medium;
    color: $color-black;
    padding: 10px 25px;
    font-size: 20px;
    height: 100%;
    position: relative;

    &:hover {
        color: $color-orange;
    }

    @include media-breakpoint-up(lg) {
        padding: 34px 0 24px;
        font-size: 20px;
        pointer-events: auto;
        margin-right: 30px;
    }
}

.navbar-item__link.has-items {
    pointer-events: none;

    @include media-breakpoint-up(lg) {
        pointer-events: auto;
    }
}

.navbar-item:hover > a {
    text-decoration: none;

    @include media-breakpoint-up(lg) {
        border-bottom: 9px solid $color-orange;
    }
}

.navbar-menu.is-open {
    overflow: visible;
    position: absolute;
    top: 60px;
    width: 100%;
    display: block;
    left: 0;
    padding: 2rem 0;
    background-color: $color-orange;
    height: calc(100vh - 60px);

    & .navbar-menu__items {
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 0;
    }

    & .navbar-item__link {
        color: $color-white;
    }
}

.navbar-hamburger {
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 60px;

    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.hamburger {
    padding: 0;
}
.hamburger:hover {
    opacity: 1;
}

.hamburger-box {
    width: 60px;
    height: 60px;
    z-index: 5;
}

.hamburger--spring .hamburger-inner {
    top: 20px;
    right: 13px;
    background-color: $color-orange;
}

.hamburger--spring .hamburger-inner::before {
    background-color: $color-orange;
    top: 10px;
}

.hamburger--spring .hamburger-inner::after {
    background-color: $color-orange;
    top: 20px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width: 30px;
    height: 3px;
}

.hamburger--spring.is-active {
    .hamburger-inner {
        top: 10px;
    }
    .hamburger-inner::before,
    .hamburger-inner::after {
        top: 10px;
        right: 0;
    }
}
</style>
