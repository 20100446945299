<template>
    <section class="block block-form pb-2 pb-md-5">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-8 offset-md-2">
                    <h1 class="block-form__title">
                        {{ title }}
                    </h1>
                    <p class="block-form__text" v-html="text"></p>
                    <ValidationObserver
                        ref="form"
                        v-slot="{
                            pristine,
                            failed,
                            handleSubmit
                        }"
                        slim
                    >
                        <form
                            class="block-form__form"
                            @submit.prevent="handleSubmit(onSubmit)"
                        >
                            <validation-provider rules="empty">
                                <input v-model="form.spam" type="hidden" />
                            </validation-provider>
                            <div class="row">
                                <div class="col-lg-6">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        rules="required"
                                    >
                                        <custom-input
                                            :id="'company'"
                                            v-model="form.company"
                                            :label="'Bedrijf'"
                                            :name="'company'"
                                            :placeholder="'Uw bedrijfsnaam'"
                                            :error-message="errors[0]"
                                            class="mb-1"
                                        ></custom-input>
                                    </validation-provider>
                                </div>

                                <div class="col-lg-6">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        rules="required"
                                    >
                                        <custom-input
                                            :id="'name'"
                                            v-model="form.name"
                                            :label="'Naam'"
                                            :name="'name'"
                                            :placeholder="'Uw naam'"
                                            :error-message="errors[0]"
                                            class="mb-1"
                                        ></custom-input>
                                    </validation-provider>
                                </div>

                                <div class="col-lg-6">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        rules="required"
                                    >
                                        <custom-input
                                            :id="'function'"
                                            v-model="form.function"
                                            :label="'Functie'"
                                            :name="'function'"
                                            :placeholder="'Uw functie'"
                                            :error-message="errors[0]"
                                            class="mb-1"
                                        ></custom-input>
                                    </validation-provider>
                                </div>

                                <div class="col-lg-6">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        rules="required|email"
                                    >
                                        <custom-input
                                            :id="'email'"
                                            v-model="form.email"
                                            :label="'E-mailadres'"
                                            :name="'email'"
                                            :placeholder="'Uw e-mail'"
                                            :error-message="errors[0]"
                                            class="mb-1"
                                        ></custom-input>
                                    </validation-provider>
                                </div>

                                <div class="col-lg-6">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        rules="required"
                                    >
                                        <custom-input
                                            :id="'phone'"
                                            v-model="form.phone"
                                            :label="'Telefoon'"
                                            :name="'phone'"
                                            :placeholder="'Uw telefoonnummer'"
                                            :error-message="errors[0]"
                                            class="mb-1"
                                            :type="'tel'"
                                        ></custom-input>
                                    </validation-provider>
                                </div>

                                <div class="col-lg-6">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        rules="required"
                                    >
                                        <custom-input
                                            :id="'address'"
                                            v-model="form.address"
                                            :label="'Straat en huisnummer'"
                                            :name="'address'"
                                            :placeholder="
                                                'Uw straat en huisnummer'
                                            "
                                            :error-message="errors[0]"
                                            class="mb-1"
                                        ></custom-input>
                                    </validation-provider>
                                </div>

                                <div class="col-lg-6">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        rules="required"
                                    >
                                        <custom-input
                                            :id="'postalcode'"
                                            v-model="form.postalcode"
                                            :label="'Postcode'"
                                            :name="'postalcode'"
                                            :placeholder="'Uw postcode'"
                                            :error-message="errors[0]"
                                            class="mb-1"
                                        ></custom-input>
                                    </validation-provider>
                                </div>

                                <div class="col-lg-6 mb-1">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        rules="required"
                                    >
                                        <custom-input
                                            :id="'city'"
                                            v-model="form.city"
                                            :label="'Woonplaats'"
                                            :name="'city'"
                                            :placeholder="'Uw woonplaats'"
                                            :error-message="errors[0]"
                                            class="mb-1"
                                        ></custom-input>
                                    </validation-provider>
                                </div>

                                <div class="col-12 col-lg-6 mb-1 mb-lg-0">
                                    <span
                                        v-if="pristine && !failed"
                                        class="float-left"
                                        :class="
                                            success
                                                ? 'text-success'
                                                : 'text-danger'
                                        "
                                        >{{ submitMessage }}</span
                                    >
                                </div>
                                <div class="col-12 col-lg-6">
                                    <button
                                        :disabled="loading"
                                        type="submit"
                                        class="btn btn-orange float-right block-form__submit"
                                    >
                                        <span v-if="!loading"
                                            >Aanvragen
                                            <span
                                                class="fas fa-chevron-right block-form__submit-icon"
                                            ></span
                                        ></span>
                                        <span
                                            v-else
                                            class="spinner-border block-form__loader"
                                        >
                                            <span class="sr-only"
                                                >Loading...</span
                                            >
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import axios from 'axios';

import csrfMixin from '../mixins/csrfMixin.js';

import customInput from './custom-input';

extend('required', {
    ...required,
    message: 'Dit veld is vereist.'
});

extend('email', {
    ...email,
    message: 'Dit veld moet een valide e-mailadres bevatten.'
});

extend('empty', value => {
    if (value === '') {
        return true;
    }

    return 'This value must be empty';
});

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        customInput
    },
    mixins: [csrfMixin],
    props: {
        title: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            loading: false,
            submitMessage: '',
            success: false,
            form: {
                id: 0,
                name: '',
                company: '',
                function: '',
                email: '',
                address: '',
                postalcode: '',
                city: '',
                phone: '',
                spam: ''
            }
        };
    },
    mounted() {
        this.form.id = this.$root.currentPage;
    },
    methods: {
        onSubmit() {
            this.loading = true;
            const promise = axios.post(
                '/umbraco/surface/form/blockform',
                this.form,
                {
                    headers: {
                        RequestVerificationToken: this.csrfToken
                    }
                }
            );

            promise
                .then(response => {
                    if (!response.data.success) {
                        this.submitMessage =
                            'Er ging iets verkeerd bij het aanvragen. Probeer het nog eens.';
                    } else {
                        this.submitMessage =
                            'Jouw aanvraag is succesvol verstuurd!';

                        // Empty form fields
                        for (const prop in this.form) {
                            if (prop !== 'id') this.form[prop] = '';
                        }
                    }
                    this.success = response.data.success;
                })
                .catch(() => {
                    this.submitMessage =
                        'Er ging iets verkeerd bij het aanvragen. Probeer het nog eens.';
                    this.success = false;
                })
                .finally(() => {
                    this.loading = false;

                    this.$nextTick(() => {
                        this.$refs.form.reset();
                    });
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.block-form__submit {
    width: 100%;
    height: auto;
}

.block-form__submit-icon {
    display: inline-block;
    margin-left: 20px;
}

@include media-breakpoint-up(lg) {
    .block-form__submit {
        width: auto;
    }
}
</style>
