import Vue from 'vue';
import WebFontLoader from 'webfontloader';

import BlockTextSlider from '@/components/block-text-slider/block-text-slider';
import BlockForm from '@/components/block-form';
import NavBar from '@/components/navbar/navbar';

import '@/scss/main.scss';

import sentry from '@/plugins/sentry';
sentry.init(window.settings.environment, window.settings.sentryDSN);

Vue.config.productionTip = false;

window.app = new Vue({
    el: '#app',
    components: {
        BlockTextSlider,
        BlockForm,
        NavBar
    },
    data: {
        currentPage: 0,
        settings: {}
    },
    created() {
        this.settings = window.settings;
        this.currentPage = document.body.dataset.currentPage;
        WebFontLoader.load({
            google: {
                families: ['Barlow:400,600,800', 'Open Sans']
            }
        });
    }
});
