var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"block block-form pb-2 pb-md-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-8 offset-md-2"},[_c('h1',{staticClass:"block-form__title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('p',{staticClass:"block-form__text",domProps:{"innerHTML":_vm._s(_vm.text)}}),_c('ValidationObserver',{ref:"form",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var pristine = ref.pristine;
                    var failed = ref.failed;
                    var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"block-form__form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('validation-provider',{attrs:{"rules":"empty"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.spam),expression:"form.spam"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.form.spam)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "spam", $event.target.value)}}})]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('custom-input',{staticClass:"mb-1",attrs:{"id":'company',"label":'Bedrijf',"name":'company',"placeholder":'Uw bedrijfsnaam',"error-message":errors[0]},model:{value:(_vm.form.company),callback:function ($$v) {_vm.$set(_vm.form, "company", $$v)},expression:"form.company"}})]}}],null,true)})],1),_c('div',{staticClass:"col-lg-6"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('custom-input',{staticClass:"mb-1",attrs:{"id":'name',"label":'Naam',"name":'name',"placeholder":'Uw naam',"error-message":errors[0]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)})],1),_c('div',{staticClass:"col-lg-6"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('custom-input',{staticClass:"mb-1",attrs:{"id":'function',"label":'Functie',"name":'function',"placeholder":'Uw functie',"error-message":errors[0]},model:{value:(_vm.form.function),callback:function ($$v) {_vm.$set(_vm.form, "function", $$v)},expression:"form.function"}})]}}],null,true)})],1),_c('div',{staticClass:"col-lg-6"},[_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('custom-input',{staticClass:"mb-1",attrs:{"id":'email',"label":'E-mailadres',"name":'email',"placeholder":'Uw e-mail',"error-message":errors[0]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1),_c('div',{staticClass:"col-lg-6"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('custom-input',{staticClass:"mb-1",attrs:{"id":'phone',"label":'Telefoon',"name":'phone',"placeholder":'Uw telefoonnummer',"error-message":errors[0],"type":'tel'},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})]}}],null,true)})],1),_c('div',{staticClass:"col-lg-6"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('custom-input',{staticClass:"mb-1",attrs:{"id":'address',"label":'Straat en huisnummer',"name":'address',"placeholder":'Uw straat en huisnummer',"error-message":errors[0]},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})]}}],null,true)})],1),_c('div',{staticClass:"col-lg-6"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('custom-input',{staticClass:"mb-1",attrs:{"id":'postalcode',"label":'Postcode',"name":'postalcode',"placeholder":'Uw postcode',"error-message":errors[0]},model:{value:(_vm.form.postalcode),callback:function ($$v) {_vm.$set(_vm.form, "postalcode", $$v)},expression:"form.postalcode"}})]}}],null,true)})],1),_c('div',{staticClass:"col-lg-6 mb-1"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('custom-input',{staticClass:"mb-1",attrs:{"id":'city',"label":'Woonplaats',"name":'city',"placeholder":'Uw woonplaats',"error-message":errors[0]},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}})]}}],null,true)})],1),_c('div',{staticClass:"col-12 col-lg-6 mb-1 mb-lg-0"},[(pristine && !failed)?_c('span',{staticClass:"float-left",class:_vm.success
                                            ? 'text-success'
                                            : 'text-danger'},[_vm._v(_vm._s(_vm.submitMessage))]):_vm._e()]),_c('div',{staticClass:"col-12 col-lg-6"},[_c('button',{staticClass:"btn btn-orange float-right block-form__submit",attrs:{"disabled":_vm.loading,"type":"submit"}},[(!_vm.loading)?_c('span',[_vm._v("Aanvragen "),_c('span',{staticClass:"fas fa-chevron-right block-form__submit-icon"})]):_c('span',{staticClass:"spinner-border block-form__loader"},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])])])],1)]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }